import "bootstrap-icons/font/bootstrap-icons.css";

const Footer = () => {
  return (
    <div>
      <div>
        <footer
          className="w-100 py-4 flex-shrink-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
        >
          <div className="container py-4">
            <div className="row gy-4 gx-5">
              <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                <ul className="list-unstyled list-inline social text-center">
                  <li className="list-inline-item">
                    <a
                      href="https://twitter.com/FoolsFruity"
                      target={"_blank"}
                      style={{
                        color: "white",
                        fontSize: 20,
                        marginRight: 10,
                      }}
                    >
                      <i className="bi bi-twitter media-item"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/fruityfools/"
                      target={"_blank"}
                      style={{ color: "white", fontSize: 20, marginRight: 10 }}
                    >
                      <i className="bi bi-instagram media-item"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://discord.gg/fruityfools"
                      target={"_blank"}
                      style={{ color: "white", fontSize: 20, marginRight: 10 }}
                    >
                      <i className="bi bi-discord media-item" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                  <p className="h6">
                    © All right Reserved{" "}
                    <a
                      className="text-green ml-2 footer-link"
                      href="#"
                      target="_blank"
                    >
                      Fruity Fools
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
